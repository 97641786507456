import { z } from '@invisible/zod'

const schema = z.object({
  jsonString: z.string(),
  inputBaseVariableIds: z.string().uuid().array(),
  outputBaseVariableIds: z.string().uuid().array(),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
