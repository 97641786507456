import {
  TBaseRunVariable,
  useWizardState,
} from '@invisible/common/components/providers/active-wizard-provider'
import { classNames } from '@invisible/common/helpers'
import { commonMIMETypes } from '@invisible/common/types'
import { logger } from '@invisible/logger/client'
import { Button } from '@invisible/ui/button'
import { CopyOutlineIcon } from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import axios from 'axios'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useGate } from 'statsig-react'

import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import ThreeDimensionModelViewer from './ThreeDimensionImageWAC'

type IProps = WizardSchemas.WACConfig.TSchema & {
  width: number
  height: number
  baseRun: TBaseRun
  wizardInitialBRVs: TBaseRunVariable[]
  twoDimImageValue: WizardSchemas.WACConfig.TValue | undefined
  threeDimImageValue: WizardSchemas.WACConfig.TValue | undefined
}

type TBaseRun = TBaseRunQueryData['items'][number]

const ZOOM_STEP = 0.1
const ROTATION_STEP = 45 // Degrees
const GOOGLE_DRIVE_IMAGE_REGEX = /^https:\/\/drive\.google\.com\/file\/d\/(.+)\/view/

// eslint-disable-next-line @typescript-eslint/ban-types
export const ImageWAC: FC<IProps> = ({
  twoDimImageValue,
  threeDimImageValue,
  value,
  showName,
  name,
  image,
  wizardInitialBRVs,
}) => {
  const { value: enableThreeDimModelViewer } = useGate('enable-three-dim-model-viewer')
  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [copied, setCopied] = useState<Record<number, boolean>>({})
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [is2D, setIs2D] = useState(true)
  const [is3D, setIs3D] = useState(false)
  const [currentStaticImageUrl, setCurrentStaticImageUrl] = useState<string>('')
  const [currentThreeDimImageUrl, setCurrentThreeDimImageUrl] = useState<string>('')
  const [currentDescription, setCurrentDescription] = useState<string>('')
  // refs
  const wacWrapper = useRef<HTMLDivElement | null>(null)
  const previewImageRef = useRef<HTMLImageElement | null>(null)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [threeDimImageUrls, setThreeDimImageUrls] = useState<string[]>([])
  const { state: wizardState } = useWizardState()

  const parsedImageUrls: string[] = useMemo(() => {
    if (typeof value === 'string') return value.split(',').map(parseDriveImageUrl)
    if (Array.isArray(value)) return value.map(parseDriveImageUrl)
    return []
  }, [value])

  const descriptionTexts = useMemo(() => {
    const value = (wizardInitialBRVs.find(
      (brv) => brv.baseVariableId === image?.imageDescriptionBaseVariableId
    )?.value ?? []) as string[]

    if (typeof value === 'string') return (value as string).split(',')
    return value
  }, [image?.imageDescriptionBaseVariableId, wizardInitialBRVs])

  const parseImageSignedUrls = (imageValue: any, wizardState: any) => {
    if (typeof imageValue === 'string') {
      return (imageValue as string)
        .split(',')
        .map((value: string) => parseDriveImageUrl(value.trim()))
    }

    if (Array.isArray(imageValue)) {
      return imageValue.map((value: string) => parseDriveImageUrl(value.trim()))
    }

    const bvId = typeof imageValue !== 'number' ? imageValue?.baseVariableId : undefined
    const baseVariables: { baseVariableId: string; value: string }[] = Array.isArray(
      wizardState.baseRun?.baseRunVariables
    )
      ? (wizardState.baseRun?.baseRunVariables as any[]).map((variable) => ({
          baseVariableId: variable.baseVariableId,
          value: variable.value,
        }))
      : []
    const value = (baseVariables?.find((brv) => brv.baseVariableId === ((bvId ?? '') as string))
      ?.value ?? []) as string[]

    if (typeof value === 'string') {
      return (value as string).split(',').map(parseDriveImageUrl)
    }

    if (Array.isArray(value)) {
      return value.map(parseDriveImageUrl)
    }

    return []
  }

  const parsedTwoDimImageUrls = useMemo(
    () => parseImageSignedUrls(twoDimImageValue, wizardState),
    [twoDimImageValue, wizardState]
  )
  const parsedThreeDimImageUrls = useMemo(
    () => parseImageSignedUrls(threeDimImageValue, wizardState),
    [threeDimImageValue, wizardState]
  )

  /**
   * Extracts file details from Google Cloud URL and returns an object with the details of the file in the URL such as the file name, type, directory name and bucket name.
   * @param url The Google Cloud Bucket File URL
   * @returns The file details extracted from the URL such as the file name, type, directory name and bucket name.
   */
  const getFileDetailsFromGoogleCloudUrl = (url: string) => {
    const urlObject = new URL(url)
    const pathParts = urlObject.pathname.split('/')

    const bucketName = pathParts[1]
    const directoryName = pathParts.slice(2, -1).join('/')

    const fileNameWithExtension = pathParts[pathParts.length - 1]
    const extension = fileNameWithExtension.split('.')[1]
    const type = commonMIMETypes[extension]
    const file = directoryName ? `${directoryName}/${fileNameWithExtension}` : fileNameWithExtension

    return { file, type, bucketName }
  }

  const getTransformOrigin = (rotation: number, scale: number) => {
    const adjustValue = (base: number, scaleFactor: number, multiplier = 1) =>
      Math.max(0, Math.min(100, base + (scale - 1.0) * scaleFactor * multiplier))

    let transformOriginX = 0
    let transformOriginY = 0

    switch (rotation) {
      case 45:
        transformOriginX = adjustValue(40, -10, 1.2)
        transformOriginY = adjustValue(60, 10, 1.2)
        return `${transformOriginX}% ${transformOriginY}%`

      case 90:
        transformOriginX = adjustValue(50, -10, 1.2)
        transformOriginY = adjustValue(50, 10, 1.2)
        return `${transformOriginX}% ${transformOriginY}%`

      case 135:
        transformOriginY = adjustValue(60, 10, 10)
        return scale < 2.0 ? `50% ${transformOriginY}%` : 'bottom center'

      case 180:
        transformOriginY = adjustValue(50, 10, 1.5)
        return `center ${transformOriginY}%`

      case 225:
        transformOriginX = adjustValue(50, 10, 1.0)
        transformOriginY = adjustValue(60, 10, 1.2)
        return `${transformOriginX}% ${transformOriginY}%`

      case 270:
        transformOriginX = adjustValue(50, 10, 1.0)
        transformOriginY = adjustValue(50, -10, 1.0)
        return `${transformOriginX}% ${transformOriginY}%`

      case 315:
        return '70% top'

      default:
        return 'top center'
    }
  }

  useEffect(() => {
    const applyTransformations = () => {
      if (!wacWrapper.current || !previewImageRef.current) return

      const { width, height } = wacWrapper.current.getBoundingClientRect()
      const imageHeight = isFullScreen ? height - 40 : (70 / 100) * height // 70% of the height or full height
      previewImageRef.current.style.setProperty('left', '50%')
      previewImageRef.current.style.setProperty('top', '50%')
      previewImageRef.current.style.setProperty('width', `${width}px`)
      previewImageRef.current.style.setProperty('height', `${imageHeight}px`)
      previewImageRef.current.style.setProperty(
        'transform',
        `scale(${zoom}) rotate(${rotation}deg)`
      )
      previewImageRef.current.style.setProperty(
        'transform-origin',
        getTransformOrigin(rotation, zoom)
      )
    }
    applyTransformations()
  }, [activeImageIndex, isFullScreen, zoom, rotation])

  const retrieveCloudImage = async (url: string) => {
    try {
      const { file, type, bucketName } = getFileDetailsFromGoogleCloudUrl(url)
      const response = await axios.post(
        `/api/google/cloud/signedUrl?file=${file}&type=${type}&bucketName=${bucketName}&action=read&excludeContentType=true`
      )
      const publicUrl = response.data.url
      return publicUrl
    } catch (error) {
      logger.error(`Error retrieving signed URL: ${url}`, {
        error,
      })
      return url
    }
  }

  useEffect(() => {
    if (parsedImageUrls.length > 0) {
      setImageUrls([])
      for (const imageUrl of parsedImageUrls) {
        if (
          imageUrl &&
          (imageUrl.includes('storage.googleapis.com') ||
            imageUrl.includes('storage.cloud.google.com'))
        ) {
          retrieveCloudImage(imageUrl).then((signedUrl) => {
            setImageUrls((prev) => [...prev, signedUrl])
          })
        } else {
          setImageUrls((prev) => [...prev, imageUrl])
        }
      }
    }
  }, [parsedImageUrls])

  const useImageUrls = (
    parsedImageUrls: string[],
    setImageUrls: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    useEffect(() => {
      if (parsedImageUrls.length > 0) {
        setImageUrls([])
        for (const imageUrl of parsedImageUrls) {
          if (
            imageUrl &&
            (imageUrl.includes('storage.googleapis.com') ||
              imageUrl.includes('storage.cloud.google.com'))
          ) {
            retrieveCloudImage(imageUrl).then((signedUrl) => {
              setImageUrls((prev) => [...prev, signedUrl])
            })
          } else {
            setImageUrls((prev) => [...prev, imageUrl])
          }
        }
      }
    }, [parsedImageUrls, setImageUrls])
  }

  useImageUrls(parsedTwoDimImageUrls, setImageUrls)
  useImageUrls(parsedThreeDimImageUrls, setThreeDimImageUrls)

  const resetTransformations = () => {
    setZoom(1)
    setRotation(0)
    setCopied({})
  }

  const handleRotate = () => {
    setRotation((rotation + ROTATION_STEP) % 360)
  }

  const handleZoomIn = () => {
    setZoom(zoom + ZOOM_STEP)
  }

  const handleZoomOut = () => {
    setZoom(zoom - ZOOM_STEP)
  }

  const handleTwoDimensionRender = () => {
    setIs2D(true)
    setIs3D(false)
  }

  const handleThreeDimensionRender = () => {
    setIs2D(false)
    setIs3D(true)
  }

  const getUrlToCopy = (
    staticImageUrl: string,
    is2D: boolean,
    threeDimImageUrls: string[]
  ): string => {
    if (is2D) {
      return staticImageUrl
    }

    const model_url = staticImageUrl.replace(/\.[^/.]+$/, '.glb')
    return threeDimImageUrls.includes(model_url) ? model_url : ''
  }

  const handleCopy = () => {
    if (!Number.isInteger(activeImageIndex) || activeImageIndex === null) return

    setCopied((prev) => ({ ...prev, [activeImageIndex]: true }))
    const staticImageUrl = imageUrls?.[activeImageIndex]
    const urlToCopy = getUrlToCopy(staticImageUrl, is2D, threeDimImageUrls)

    if (document.hasFocus()) {
      navigator.clipboard.writeText(urlToCopy)
    } else {
      logger.error('Cannot copy image URL to clipboard. Document is not focused.')
    }
  }

  const updateImage = (updateIndexFunction: (prev: number | null) => number) => {
    resetTransformations()
    setActiveImageIndex(updateIndexFunction)
    const index = activeImageIndex === null ? 0 : activeImageIndex
    const staticImageUrl = imageUrls?.[index] ?? ''
    setCurrentStaticImageUrl(staticImageUrl)
    setCurrentDescription(descriptionTexts?.[index])
    const model_url = staticImageUrl.replace(/\.[^/.]+$/, '.glb')
    if (threeDimImageUrls.includes(model_url)) {
      setCurrentThreeDimImageUrl(model_url)
    } else {
      setCurrentThreeDimImageUrl('')
    }
  }

  const nextImage = () => {
    updateImage((prev) => (prev === imageUrls.length - 1 ? 0 : (prev ?? 0) + 1))
  }

  const prevImage = () => {
    updateImage((prev) => (prev === 0 ? imageUrls.length - 1 : (prev ?? 0) - 1))
  }

  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev)
  }

  const handleClick = (index: number) => {
    setActiveImageIndex(index)
    const staticImageUrl = imageUrls?.[index]
    setCurrentStaticImageUrl(staticImageUrl)
    const modelUrl = staticImageUrl?.replace(/\.[^/.]+$/, '.glb')
    setCurrentThreeDimImageUrl(threeDimImageUrls.includes(modelUrl) ? modelUrl : '')
  }

  return (
    <div
      ref={wacWrapper}
      className='relative box-border flex h-full flex-col overflow-auto rounded-lg border border-gray-400 bg-white p-2.5 shadow-md'>
      {showName ? <div className='mb-3 font-bold'>{name}</div> : null}
      {activeImageIndex !== null ? (
        <div>
          <div className='flex h-5 justify-between'>
            {is3D && enableThreeDimModelViewer ? (
              <div className='w-[50%] overflow-hidden text-ellipsis whitespace-nowrap font-bold'>
                {currentThreeDimImageUrl}{' '}
              </div>
            ) : (
              <div className='w-[50%] overflow-hidden text-ellipsis whitespace-nowrap font-bold'>
                {currentStaticImageUrl}{' '}
              </div>
            )}
            <div
              className={classNames(
                'text-primary flex cursor-pointer items-center',
                copied[activeImageIndex] ? 'border-primary border border-dotted' : ''
              )}
              onClick={handleCopy}>
              {' '}
              <CopyOutlineIcon width='20px' /> Copy Image URL
            </div>
          </div>
          <PreviewImage
            enableThreeDimModelViewer={enableThreeDimModelViewer}
            image={currentStaticImageUrl}
            threeDimImage={currentThreeDimImageUrl}
            imageRef={previewImageRef}
            nextImage={nextImage}
            prevImage={prevImage}
            toggleFullScreen={toggleFullScreen}
            zoomIn={handleZoomIn}
            zoomOut={handleZoomOut}
            twoDimension={handleTwoDimensionRender}
            threeDimension={handleThreeDimensionRender}
            is2D={is2D}
            is3D={is3D}
            rotate={handleRotate}
            descriptionText={currentDescription}
          />
        </div>
      ) : null}
      <div
        className={classNames(
          'grid w-full grid-cols-4 gap-2 overflow-auto py-1',
          isFullScreen ? 'hidden' : ''
        )}>
        {/* Renders image list*/}
        {imageUrls?.map((url: string, index: number) => (
          <div
            key={index}
            className={classNames(
              'relative flex items-center justify-center',
              activeImageIndex === index ? 'border-2 border-solid border-blue-500' : ''
            )}
            onClick={() => handleClick(index)}>
            <img
              key={index}
              src={url}
              alt={url}
              className='max-h-full max-w-full cursor-pointer rounded-sm object-contain'
            />
          </div>
        ))}
      </div>
    </div>
  )
}

type PreviewImageProps = {
  enableThreeDimModelViewer: boolean
  image: string
  threeDimImage: string
  imageRef: React.RefObject<HTMLImageElement>
  nextImage: () => void
  prevImage: () => void
  toggleFullScreen: () => void
  zoomIn: () => void
  zoomOut: () => void
  twoDimension: () => void
  threeDimension: () => void
  is2D: boolean
  is3D: boolean
  rotate: () => void
  descriptionText?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const PreviewImage: FC<PreviewImageProps> = ({
  enableThreeDimModelViewer,
  image,
  threeDimImage,
  imageRef,
  nextImage,
  prevImage,
  toggleFullScreen,
  zoomIn,
  zoomOut,
  twoDimension,
  threeDimension,
  is2D,
  is3D,
  rotate,
  descriptionText,
}) => (
  <div className='relative flex w-full items-center justify-center bg-black'>
    <span className='absolute left-4 top-1/2 z-20'>
      <Button
        icon='CaretLeftIcon'
        size='lg'
        variant='secondary'
        shape='circle'
        data-testid='prev-image-button'
        onClick={() => prevImage()}
      />
    </span>{' '}
    <span className='absolute right-4 top-1/2 z-20'>
      <Button
        icon='CaretRightIcon'
        size='lg'
        variant='secondary'
        shape='circle'
        data-testid='next-image-button'
        onClick={() => nextImage()}
      />
    </span>
    <div className='relative flex items-center justify-center overflow-auto rounded'>
      {is2D ? (
        <img
          ref={imageRef}
          src={image}
          alt={image}
          className='overflow-scroll rounded object-contain'
          title={descriptionText}
        />
      ) : null}
      {threeDimImage && is3D && enableThreeDimModelViewer ? (
        <ThreeDimensionModelViewer modelUrl={threeDimImage} />
      ) : null}
      <div className='absolute bottom-4 left-2 mr-40 rounded-lg bg-white px-2 py-1 text-black'>
        {descriptionText}
      </div>
    </div>
    {is3D && enableThreeDimModelViewer ? (
      <div className='border-1 absolute right-2 bottom-4 flex rounded-full border-solid border-gray-400 bg-white p-1'>
        <span>
          <Button
            icon='TwoDimensionIcon'
            size='md'
            variant='subtle'
            shape='circle'
            data-testid='2d-button'
            onClick={() => twoDimension()}
          />
        </span>
      </div>
    ) : null}
    {is2D ? (
      <div className='border-1 absolute right-2 bottom-4 flex rounded-full border-solid border-gray-400 bg-white p-1'>
        {threeDimImage && enableThreeDimModelViewer ? (
          <span>
            <Button
              icon='ThreeDimensionIcon'
              size='md'
              variant='subtle'
              shape='circle'
              data-testid='3d-button'
              onClick={() => threeDimension()}
            />
          </span>
        ) : null}
        <span>
          <Button
            icon='ZoomInIcon'
            size='md'
            variant='subtle'
            shape='circle'
            data-testid='zoom-in-button'
            onClick={() => zoomIn()}
          />
        </span>
        <span>
          <Button
            icon='ZoomOutIcon'
            size='md'
            variant='subtle'
            shape='circle'
            data-testid='zoom-out-button'
            onClick={() => zoomOut()}
          />
        </span>
        <span>
          <Button
            icon='RedoIcon'
            size='md'
            variant='subtle'
            shape='circle'
            data-testid='rotate-button'
            onClick={() => rotate()}
          />
        </span>
        <span>
          <Button
            icon='FullScreenIcon'
            size='md'
            variant='subtle'
            shape='circle'
            data-testid='fullscreen-button'
            onClick={() => toggleFullScreen()}
          />
        </span>
      </div>
    ) : null}
  </div>
)

function parseDriveImageUrl(url: string): string {
  const match = url.match(GOOGLE_DRIVE_IMAGE_REGEX)
  if (!match) return url

  const driveId = match[1]
  return `https://drive.google.com/uc?export=view&id=${driveId}`
}
